<template>
  <figure v-if="imageSrc">

    <div class="img-wrapper position-relative">
      <img v-lazy="getImage(imageSrc.src)" :alt="imageSrc.descrizione != null ? imageSrc.descrizione : imageSrc.titolo" :title="imageSrc.descrizione != null ? imageSrc.descrizione : imageSrc.titolo" class="img-fluid " :width="['listcard' ? '560' : '1280']" :height="['listcard' ? '360' : '720']" :class="[
    { ['listcard']: inList },
    { ['img-card']: inCard },
    { ['sezioneImg-card']: inSection }
  ]" rel="preload">
    </div>

    <figcaption class="didascalia font-sans-serif mb-1 pt-1" v-if="imageSrc.didascalia !== '' && !inList && !inSlider && !inCard && !inModal">
      <div v-if="imageSrc.didascalia" class="mt-2 x-small text-center text-black">{{ imageSrc.didascalia }}</div>
    </figcaption>

    <template v-if="inModal">
      <figcaption class=" font-sans-serif mb-1" v-if="imageSrc.titolo !== '' || imageSrc.didascalia !== '' || imageSrc.descrizione !== '' || imageSrc.fonte !== ''">
        <div v-if="imageSrc.didascalia" class="didascalia x-small text-center text-black">{{ imageSrc.didascalia }}</div>
        <div v-if="imageSrc.titolo" class="text-left">
          <h4 class="text-black mb-1">{{ imageSrc.titolo }}</h4>
        </div>
        <div v-if="imageSrc.descrizione" class="text-left text-black">{{ imageSrc.descrizione }}</div>
        <div v-if="imageSrc.fonte" class="border-top mt-2 pt-2 text-left small">
          <b><svg class="icon icon-sm icon-primary mr-1">
              <use xlink:href="@/assets/img/sprite.svg#it-link"></use>
            </svg>Fonte </b>
          <a class="ml-2" :href="imageSrc.fonte" target="_blank">{{ imageSrc.fonte }}</a>
        </div>
      </figcaption>
    </template>
  </figure>
</template>

<script type="text/javascript">
export default {
  name: "ImageComponent",
  components: {
  },
  props: {
    imageSrc: {
      type: Object,
      description: "String dell'immagine"
    },
    inModal: {
      type: Boolean,
      default: false
    },
    inCard: {
      type: Boolean,
      default: false
    },
    inList: {
      type: Boolean,
      default: false
    },
    inSection: {
      type: Boolean,
      default: false
    },
    inSlider: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      embed: false
    }
  },
  mounted() {
  },
  methods: {
    getImage(str) {
      if (this.$props.inModal) {
        return str.substring(0, str.indexOf('?')) + '?mode=Pad&height=' + (window.innerHeight - 100);
      }
      else {
        if (this.$props.inSlider) {
          if (str.indexOf('unsplash') > -1) {
            return str + '&mode=Max&auto=true';
          }
          else {
            return str + '&mode=Max&auto=true';
          }
        } else {
          return str;
        }
      }
    }
  },
  computed: {
  }
};
</script>
<style>
img[lazy=loading] {
  max-height: 1px !important;
  max-width: 1px !important;
}
</style>