<template>
   <AppHeader :threshold="150" @emitScrolled="scrolled" :menuList="mainMenu" />
   <Breadcrumbs :key="this.$route.fullPath" v-show="this.$route.name !== 'Home'" :lastTest="lastChild"></Breadcrumbs>
   <router-view :key="this.$route.fullPath" />
   <AppFooter />
   <transition>
      <ScrollToTop>
         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="78" height="78" viewBox="0 0 1080 1080" xml:space="preserve">
            <g transform="matrix(1 0 0 1 540 540)">
               <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke" x="-540" y="-540" rx="0" ry="0" width="1080" height="1080" />
            </g>
            <g transform="matrix(45 0 0 45 540 540)">
               <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 2; fill: rgb(2,48,71); fill-rule: nonzero; opacity: 1;" transform=" translate(-12.01, -12)" d="M 2.014 11.998 C 2.014 17.515 6.494 21.994999999999997 12.011999999999999 21.994999999999997 C 17.529999999999998 21.994999999999997 22.009 17.514999999999997 22.009 11.997999999999998 C 22.009 6.479999999999998 17.53 1.9999999999999982 12.012 1.9999999999999982 C 6.494 1.9999999999999982 2.014000000000001 6.479999999999999 2.014000000000001 11.997999999999998 z M 8.225 10.474 C 8.225 10.474 9.73 8.973 11.484 7.220000000000001 C 11.63 7.073 11.822 7.000000000000001 12.014 7.000000000000001 C 12.206 7.000000000000001 12.398 7.073000000000001 12.543999999999999 7.220000000000001 C 14.297999999999998 8.972000000000001 15.802 10.474 15.802 10.474 C 15.947 10.619 16.019 10.809000000000001 16.019 11 C 16.019 11.192 15.944999999999999 11.384 15.797999999999998 11.53 C 15.505999999999998 11.822999999999999 15.031999999999998 11.825 14.740999999999998 11.533999999999999 L 12.763999999999998 9.556999999999999 L 12.763999999999998 16.25 C 12.763999999999998 16.664 12.427999999999997 17 12.013999999999998 17 C 11.599999999999998 17 11.263999999999998 16.664 11.263999999999998 16.25 L 11.263999999999998 9.557 L 9.284999999999997 11.535 C 8.995999999999997 11.824 8.523999999999997 11.822000000000001 8.230999999999996 11.529 C 8.083999999999996 11.382 8.009999999999996 11.19 8.008999999999997 10.999 C 8.008999999999997 10.808 8.079999999999997 10.619 8.224999999999996 10.474 z" stroke-linecap="round" />
            </g>
            <g transform="matrix(0.98 0 0 1.1 540 537)">
               <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" translate(-223.95, -255.98)" d="M 34.9 289.5 L 12.7 267.3 C 3.299999999999999 257.90000000000003 3.299999999999999 242.70000000000002 12.7 233.4 L 207 39 C 216.4 29.6 231.6 29.6 240.9 39 L 435.20000000000005 233.3 C 444.6 242.70000000000002 444.6 257.90000000000003 435.20000000000005 267.2 L 413 289.4 C 403.5 298.9 388 298.7 378.7 289 L 264 168.6 L 264 456 C 264 469.3 253.3 480 240 480 L 208 480 C 194.7 480 184 469.3 184 456 L 184 168.6 L 69.2 289.1 C 59.900000000000006 298.90000000000003 44.400000000000006 299.1 34.900000000000006 289.5 z" stroke-linecap="round" />
            </g>
         </svg>
      </ScrollToTop>
   </transition>
</template>

<script>
import AppHeader from '@/layout/parts/AppHeader';
import AppFooter from '@/layout/parts/AppFooter';
import Breadcrumbs from '@/layout/parts/Breadcrumbs.vue';
import ScrollToTop from '@/components/ScrollToTop';

export default {
   name: "App",
   components: {
      AppHeader,
      AppFooter,
      Breadcrumbs,
      ScrollToTop,
   },
   data() {
      return {
         title: null,
         scrolling: false,
         lastChild: "",
         hasImage: false,
         mastheadMenu: [],
         showBackground: false,
         mainMenu: [],
         footerMenu: [],
         footerMiniMenu: [],
         layout: [],
         showBanner: true,
         appname: '<span>' + process.env.VUE_APP_MAINSITE + '</span> <b class="font-weight-normal">' + process.env.VUE_APP_SITE + '</b>'
      }
   },

   methods: {
      print() {
         window.print()
      },

      setTitle(title) {
         document.title = this.title = title + ' - ' + process.env.VUE_APP_TITLE
      },

      setMetaTags(post = null) {
         //const baseSrc = `${__dirname}/dist/index.html`;
         //const baseHTML = await fs.promises.readFile(baseSrc);
         document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href)
         document.querySelector('meta[property="og:title"]').setAttribute("content", post.titolo)

         if (post.image !== undefined && post.image !== null) {
            document.querySelector('meta[property="og:image"]').setAttribute("content", post.image.src)
         }
         else {
            document.querySelector('meta[property="og:image"]').setAttribute("content", '')
         }

         if (post.breve !== undefined && post.breve !== null) {
            document.querySelector('meta[property="og:description"]').setAttribute("content", post.breve)
            document.querySelector('meta[name="description"]').setAttribute("content", post.breve)
         }
         else {
            document.querySelector('meta[property="og:description"]').setAttribute("content", '')
            document.querySelector('meta[name="description"]').setAttribute("content", '')
         }
      },

      scrolled(val) {
         if (val) { document.querySelector('#app').classList = 'scrolled' }
         else { document.querySelector('#app').classList = '' }
      },

      getLayout() {
         return this.layout;
      },

      setPosition(postId) {
         sessionStorage.setItem('PageId', postId);
      },

      getPosition() {
         return sessionStorage.getItem('PageId');
      },

      setBreadcrumbLast(str) {
         this.lastChild = str;
      },

      setImageArea(val) {
         this.showBackground = val;
      },

      hasBackground() {
         return this.showBackground;
      }

   },

   created() {
      this.mastheadMenu = this.$store.getters.getMastHeadMenu;
      this.mainMenu = this.$store.getters.getMainMenu;
      this.footerMenu = this.$store.getters.getFooterMenu;
      this.footerMiniMenu = this.$store.getters.getMiniFooterMenu;
      this.layout = this.$store.getters.getLayout;
      document.title = process.env.VUE_APP_TITLE;
   },

   watch: {
      $route: {
         immediate: true,
         async handler(to) {
            if (this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.id !== undefined) {
               sessionStorage.setItem('PageId', this.$route.matched[0].props.default.id);
            }

            document.title = (to?.meta.breadcrumb !== undefined ? to?.meta.breadcrumb + ' - ' : '') + process.env.VUE_APP_TITLE || process.env.VUE_APP_TITLE;
            if (to !== undefined && parseInt(process.env.VUE_APP_MATOMA_ENABLED) == 1) {
               window._paq.push(['setCustomUrl', window.location.pathname]);
               window._paq.push(['setDocumentTitle', to?.meta.breadcrumb]);
               window._paq.push(['trackPageView']);
            }
         }
      },
      hasImage: function (val) {
         return val;
      },

      cookieContent: function () {
         return this.cookieContent;
      },
   }
}
</script>

<style>
.cursor {
   cursor: pointer;
}

#app.scrolled {
   padding-top: 200px;
}

#app.scrolled it-header-main h2 {
   font-size: 1.6em;
}

a:focus {
   outline: 2px solid #219EBC !important;
}

.v-enter-active,
.v-leave-active {
   transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
   opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
   opacity: 0;
}

.btn-primary:focus>svg.icon-white {
   fill: #005c99 !important;
}

.border-bottom-block::after {
   border-bottom: 5px solid white;
   content: '';
   width: 100px;
   left: 0px;
   position: absolute;
   bottom: -2px;
}

.border-bottom-block.primary-border-color::after {
   border-color: #023047 !important;
}

.it-brand-wrapper.cloned {
   display: none;
}

.it-header-sticky.vue-fixed-header--isFixed .it-brand-wrapper.cloned {
   transition: all 1s ease;
   display: block;
}

.navbar-collapsable.expanded {
   display: block;
   transition: all 1s ease;
   font-size: 1.4rem;
}

.navbar-collapsable.expanded .overlay {
   display: block;
   transition: all 1s ease;
}

.navbar .navbar-collapsable.expanded.d-block .menu-wrapper {
   padding-top: 0;
   width: 360px;
}

.sottosezione.ss-top {
   display: block;
   margin-top: 2.0em;
}

a.nav-link:hover {
   text-decoration: underline;
}

.bordered {
   border: 1px solid transparent;
}

.complementary-2-border {
   border-color: #BA3E05 !important;
}

.ScrollToTop {
   background-color: transparent !important;
   border: 0px !important;
   position: fixed;
   bottom: 20px;
   right: 20px;
   cursor: pointer;
   transition: all 2.5s ease;
}

.ScrollToTop img {
   max-width: 48px;
   width: 48px;
}

#mainArticle {
   font-size: 18px;
   line-height: 28px;
}

#mainArticle a {
   font-weight: 600;
   word-wrap: break-word;
}

#mainArticle a:hover {
   text-decoration: underline;
}

#mainArticle a:visited {
   color: #666 !important;
}

#mainArticle .avviso.bg-primary a {
   color: #FFF !important;
   text-decoration: underline !important;
}

#mainArticle .avviso.bg-primary a:hover {
   text-decoration: underline !important;
}

.page .col-12.column-count-2 {
   column-count: 2;
   column-gap: 20px;
}

/* Cards */
.card-with-image {
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}


.card-with-video-embed figure,
.card-with-image figure {
   margin: 0px;
}

.card-with-video-embed .figcaption,
.card-with-video .figcaption,
.card-with-image figcaption,
.card-with-image .figcaption {
   background: #FFF;
   margin: 1.0rem;
   padding: 1.0rem;
   position: absolute;
   text-align: center;
   bottom: 1rem;
   right: 1rem;
   left: 1rem;
   color: #000;
   font-weight: 600;
   font-size: 0.88889rem;
}

.card-with-no-image,
.card-with-no-image>div,
.card-with-no-image div.position-relative {
   position: relative;
   height: 100%;
}

.card-with-no-image .figcaption {
   background: #FFF;
   margin: 1.0rem;
   padding: 1.0rem;
   position: relative;
   text-align: center;
   right: auto;
   left: auto;
   color: #000;
   font-weight: 600;
   font-size: 0.88889rem;
}

.card-img.listcard {
   min-height: 160px !important;
   max-height: 200px !important;
   min-width: 320px;
   min-height: 280px;
   min-width: 320px;
   object-fit: cover;
}

.card.card-with-video .img-responsive-wrapper .img-responsive {
   padding-bottom: 75%;
}

.card.card-with-image .img-responsive-wrapper .img-responsive {
   padding-bottom: 75%;
}

.card.card-with-video .img-responsive-wrapper .img-responsive .single-video {
   margin-bottom: 0px;
}

.card.card-with-video-embed .img-responsive-wrapper .img-responsive {
   padding-bottom: 75%;
   min-height: 160px !important;
   max-height: 200px !important;
   min-width: 320px;
   min-height: 280px;
}

.card-with-video .video-container {
   max-height: 280px;
}

.img-fluid.sezioneImg {
   min-height: 186px;
   min-width: 280px;
   object-fit: cover;
   max-height: 560px
}

.img-fluid.sezioneImg.img-card,
.img-fluid.sezioneImg-card {
   min-height: 280px;
}

.img-fluid.sezioneImg.video-card {
   min-height: 280px;
}

.listcard.img-fluid.sezioneImg.video-card {
   min-height: 186px;
}

.card .card-body .card-text {
   min-height: 160px !important;
   /*max-height:354px !important; overflow:hidden; text-overflow: ellipsis;*/
}

.listcard {
   min-height: 180px !important;
}

.listcard-iframe {
   min-height: 180px !important;
}

/*modals*/
.single.video .galleryDidascalia {
   text-align: center;
   font-size: .777rem;
   padding-top: 8px;
   width: 100%;
   position: absolute;
   bottom: -60px !important;
   height: 60px;
   color: #000;
   background: unset !important;
}

.striscia-bg {
   background-color: #ededed !important;
}

.galleria .carousel__icon {
   color: #FFF;
}

.galleria .carousel__pagination {
   top: unset !important;
}

.modal .carousel__pagination .carousel__pagination-button,
.galleria-immagini .carousel__pagination .carousel__pagination-button,
.galleria .carousel__pagination .carousel__pagination-button {
   margin: auto 8px;
   background-color: transparent !important;
   box-shadow: unset !important;
   width: auto !important;
   z-index: 300;
}

.modal .carousel__pagination .carousel__pagination-button::after,
.galleria .carousel__pagination .carousel__pagination-button::after,
.galleria-immagini .carousel__pagination .carousel__pagination-button::after {
   width: 16px;
   height: 16px;
   border-radius: 8px;
}

.modal .carousel__item,
.galleria .carousel__item {
   border: 4px solid #FFF;
   max-width: 100%;
   border-top: 0px;
   border-bottom: 0px;
   width: 100%;
   max-width: 100%;
   height: 100%;
}

.galleria .carousel__item img {
   object-fit: cover;
   max-width: 100%;
   height: 100%;
}

.galleryDidascalia {
   text-align: center;
   font-size: .777rem;
   font-weight: 600;
   font-family: "Titillium Web", Geneva, Tahoma, sans-serif;
   width: 100%;
   position: absolute;
   bottom: 0px !important;
   min-height: 36px;
   background: rgba(0, 0, 0, 0.50);
   border: 4px solid #FFF;
   max-width: 100%;
   border-top: 0px;
   border-bottom: 0px;
   color: #FFF;

   display: flex;
   align-items: center;
   justify-content: center;
   align-items: center;
   line-height: 1.25em;
}

.video-galleria .carousel__viewport {
   padding-bottom: 48px;
}

.video-galleria .galleryDidascalia {
   text-align: center;
   font-size: .777rem;
   padding-top: 8px;
   width: 100%;
   position: absolute;
   bottom: -60px !important;
   height: 60px;
   color: #000;
   background: unset !important;
}

.video-galleria .carousel__pagination {
   top: -80px !important;
}

.galleria .carousel__prev {
   left: 0px !important;
   top: 45%;
}

.galleria .carousel__next {
   right: 0px !important;
   top: 45%;
}

.img-fluid {

   width: 100%;
}

.video-container video {
   object-fit: fill;
}

/* Search */
input[type="search"]:placeholder-shown+.input-group-append>.reset {
   display: none;
}

.resetArea {
   position: absolute;
   z-index: 150;
   border-left: 0px;
   border-right: 0px;
   border-bottom: 1px solid #5c6f82;
   height: 100%;
   display: flex;
   flex-direction: row-reverse;
   right: 120px;
   width: 60px;
}

.resetArea.generic {
   right: 86px;
}

.input-group-append .btn[type="reset"] {
   float: right;
   position: relative;
   background-color: #FFf;
   padding: 0px 8px;
   border: 0px !important;
}

/* Chips */
.chip a {
   color: #000;
}

.w-33 {
   width: 33%
}

.w-66 {
   width: 66%
}

.modal .modal-dialog {
   margin: auto !important
}


@media screen and (max-width:560px) {
   .modal .modal-dialog .modal-content .modal-body {
      padding: 16px !important;
   }

   .modal button.close {
      right: 32px !important;
   }

   .modal .modal-dialog {
      margin: 8px !important
   }
}
</style>