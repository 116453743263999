<template>
   <span class="chip mr-2 my-1 chip-primary bg-primary closable text-white x-small">
      {{ tagTitle }}

      <button class="" @click="this.$emit('removeChip', tagTitle)">
         <svg class="icon icon-xs icon-white ml-auto">
            <use xlink:href="@/assets/img/sprite.svg#it-close-circle"></use>
         </svg>
      </button>
   </span>
</template>

<script>
export default {
   name: "ComponentChipEliminable",
   props: {
      tagTitle: {
         type: String,
         required: false
      },
      isTag: {
         type: Boolean,
         default: false
      },
      isProgram: {
         type: Boolean,
         default: false
      }
   },
   emits: ['removeChip'],
   computed: {
      tags() {
         if (typeof this.$props.tagTitle === 'string') {
            return this.$props.tagTitle
         }
         else {
            if (this.$props.tagTitle === undefined || this.$props.tagTitle.iv === undefined) {
               return this.$props.tagTitle
            }
            else {
               if (typeof this.$props.tagTitle.iv === 'string') {
                  return new Array(this.$props.tagTitle.iv)
               }
               else {
                  return this.$props.tagTitle.iv
               }
            }
         }
      },

      getContentPadrePath() {
         let path = ''
         let typeObj = typeof this.$props.tagTitle;
         let pathArr = []

         if (this.$props.tagTitle !== undefined && (typeObj === 'array' || typeObj === 'object')) {
            if (typeObj === 'object') {
               if (this.$props.tagTitle[0] !== undefined) {
                  path = '/programmi/'
                  path += this.$props.tagTitle[0].contenutoPadre.slug.iv + '/' + this.$props.tagTitle[0].slug;

               }
               else {
                  pathArr.push(this.$props.tagTitle.slug)

                  if (this.$props.tagTitle.contenutoPadre !== undefined && this.$props.tagTitle.contenutoPadre[0] !== undefined) {
                     pathArr.push(this.$props.tagTitle.contenutoPadre[0].flatData.slug)

                     if (this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre !== null && this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre !== undefined) {
                        pathArr.push(this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.slug)

                        if (this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre !== null && this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre !== undefined) {
                           pathArr.push(this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.slug)
                        }
                     }
                  }
                  else {
                     if (this.$props.tagTitle.contenutoPadre !== undefined) {
                        pathArr.push(this.$props.tagTitle.contenutoPadre.slug.iv)
                        pathArr.push('programmi')
                     }
                  }

                  path = '/' + pathArr.reverse().join('/');
               }
            }
            else {
               path = '#';
            }
         }
         return path;
      }
   }
};
</script>
<style scoped>
.closable {
   align-items: center;
   margin-left: auto;
}

.chip .chip-label {
   height: auto;
}

.closable button {
   width: 16px;
   height: 16px;
   position: relative;
   margin-left: 8px;
}

.closable button .icon {
   width: 16px;
   height: 16px;
   position: absolute;
   top: 0px;
   right: 0px;
   fill: white;
}

.chip-primary.closable:hover .icon {
   fill: #023047 !important;
}

.chip-primary.closable:hover {
   color: #023047 !important;
   background-color: #FFF !important;
   border-color: #023047 !important;
}
</style>
