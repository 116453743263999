<template>
  <span v-if="typeof tagTitle === 'string' && (!isProgram && !inList)" class="chip chip-default mr-2 my-1 small" :class="[
    { ['chip-primary closable']: isEliminable },
  ]">
    <router-link :to="(url !== '' ? url : ('/cerca?' + (isProgram ? 'programma=' : 'tag=') + encodeURIComponent(tagTitle)))" class="text-decoration-none">
      <b :class="[
    { ['basic tag']: isTag },
    { ['text-no-wrap chip-program']: isProgram },
    { ['chip-label']: isEliminable },
  ]">
        {{ tagTitle }}
      </b>
    </router-link>
    <template v-if="isEliminable">
      <button class="" @click="this.$emit('removeChip', $event, tag)">
        <svg class="icon icon-xs icon-white ml-1">
          <use xlink:href="@/assets/img/sprite.svg#it-close-circle"></use>
        </svg>
      </button>
    </template>
  </span>

  <template v-else-if="isProgram === true && typeof tagTitle === 'object'">
    <span class="chip chip-default mr-2 my-1 small chip-program">
      <router-link :to="getContentPadrePath" class="text-decoration-none">
        <b :class="[
    { ['basic tag']: isTag },
    { ['text-no-wrap']: isProgram },
    { ['chip-primary closable']: isEliminable },
  ]">
          {{ tagTitle.nomeProgramma !== undefined ? tagTitle.nomeProgramma : (tagTitle[0] !== undefined ? tagTitle[0].nome : tagTitle.nome) }}
        </b>
      </router-link>
    </span>
  </template>

  <template v-else-if="isProgram === true && typeof tagTitle === 'string'">
    <span class="chip chip-default mr-2 my-1 small chip-program">
      <b :class="[
    { ['basic tag']: isTag },
    { ['text-no-wrap']: isProgram },
    { ['chip-label btn-rimary']: isEliminable },
  ]">
        {{ tagTitle }}
      </b>
    </span>
  </template>

  <template v-else>
    <span v-for="(tagVal, index) in tags" :key="index" class="chip chip-default mr-2 my-1 small" :class="[
    { ['basic tag']: isTag },
    { ['chip-program']: isProgram },
    { ['chip-primary closable']: isEliminable },
  ]">
      <router-link :to="'/cerca?' + (isProgram ? 'programma=' : 'topic=') + encodeURIComponent(tagVal)" class="text-decoration-none" v-if="!isEliminable">
        <b :class="[
    { ['text-no-wrap']: isProgram },
    { ['chip-label']: isEliminable },
  ]">
          {{ tagVal }}
        </b>
      </router-link>


      <span class="chip-label pt-1" v-else>
        {{ tagVal.split(':')[1] }}
      </span>

      <template v-if="isEliminable">
        <button class="" @click="this.$emit('removeChip', tagVal)">
          <svg class="icon icon-xs icon-white ml-auto">
            <use xlink:href="@/assets/img/sprite.svg#it-close-circle"></use>
          </svg>
        </button>
      </template>
    </span>
  </template>
</template>
<script>
export default {
  name: "Chip",
  props: {
    tagTitle: {
      type: [String, Array, Object],
      required: false
    },
    isTag: {
      type: Boolean,
      default: false
    },
    isProgram: {
      type: Boolean,
      default: false
    },
    inList: {
      type: Boolean,
      default: false
    },
    isEliminable: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  emits: ['removeChip'],
  computed: {
    tags() {
      if (typeof this.$props.tagTitle === 'string') {
        return this.$props.tagTitle
      }
      else {

        if (this.$props.tagTitle === undefined || this.$props.tagTitle === null) {
          return;
        }
        else {
          if (this.$props.tagTitle !== undefined && this.$props.tagTitle.iv !== undefined && typeof this.$props.tagTitle.iv === 'string') {
            return new Array(this.$props.tagTitle.iv)
          }
          else {
            return this.$props.tagTitle
          }

        }
      }
    },

    getContentPadrePath() {
      let path = ''
      let typeObj = typeof this.$props.tagTitle;
      let pathArr = []


      if (this.$props.tagTitle !== undefined && (typeObj === 'array' || typeObj === 'object')) {
        if (this.$props.tagTitle.contenutoLink !== undefined && this.$props.tagTitle.contenutoLink !== null) {
          path = this.$props.tagTitle.contenutoLink
        }
        else {
          if (typeObj === 'object') {
            if (this.$props.tagTitle[0] !== undefined) {
              path = '/programmi/'
              path += this.$props.tagTitle[0].contenutoPadre?.slug.iv + '/' + this.$props.tagTitle[0].slug;
            }
            else {
              pathArr.push(this.$props.tagTitle.slug)

              if (this.$props.tagTitle.contenutoPadre !== undefined && this.$props.tagTitle.contenutoPadre[0] !== undefined) {
                pathArr.push(this.$props.tagTitle.contenutoPadre[0].flatData.slug)

                if (this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre !== null && this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre !== undefined) {
                  pathArr.push(this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.slug)

                  if (this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre !== null && this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre !== undefined) {
                    pathArr.push(this.$props.tagTitle.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.slug)
                  }
                }
              }
              else {
                if (this.$props.tagTitle.contenutoPadre !== undefined) {
                  pathArr.push(this.$props.tagTitle.contenutoPadre.slug.iv)
                  pathArr.push('programmi')
                }
              }

              path = '/' + pathArr.reverse().join('/');
            }
          }
          else {
            path = '#';
          }

        }

      }
      return path;
    }
  }
};
</script>
<style scoped>
.closable {
  align-items: center;
  margin-left: auto;
}

.chip .chip-label {
  height: auto;
}

.closable button {
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 8px;
}

.closable button .icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
  fill: white;
}

.chip-primary.closable:hover .icon-white {
  fill: #023047 !important
}
</style>
